<template>
    <div>
        <section class="hero is-primary">
            <div class="hero-body">
                <p class="title">
                    Support
                </p>
                <p class="subtitle">
                  Contact Movies and Filmmaking
                </p>
            </div>
        </section>
        <div class="container mt-3">
          <div class="columns is-justify-content-center is-multiline">
            <div class="column is-6">
              <div class="card">
                <div class="card-header">
                  <div class="card-header-title">
                    <b-icon icon="phone" />
                    <h1 class="is-size-3 ml-2">Contact us</h1>
                  </div>
                </div>

                <form @submit="submitContact" class="card-content">
                  <b-field label="Email*">
                    <b-input
                      v-model="contact.email"
                      placeholder="clappy@mafdiscord.com"
                      required
                      type="email"
                      maxlength="2048"
                      expanded>
                    </b-input>
                  </b-field>

                  <b-field label="Subject*">
                    <b-input
                      v-model="contact.subject"
                      placeholder="Popcorn party"
                      required
                      maxlength="256"
                      expanded>
                    </b-input>
                  </b-field>

                  <b-field label="Description*">
                    <b-input
                      v-model="contact.description"
                      placeholder="I want to throw a popcorn party"
                      type="textarea"
                      maxlength="4096"
                      required
                      expanded>
                    </b-input>
                  </b-field>

                  <b-field label="Discord ID">
                    <b-input
                      v-model="contact.discordId"
                      placeholder="131857875973701633"
                      pattern="^[0-9]{16,20}$"
                      maxlength="20"
                      expanded>
                    </b-input>
                  </b-field>

                  <b-button
                    :loading="contactLoading"
                    native-type="submit"
                    label="Contact"
                    type="is-info"/>
                </form>
              </div>
            </div>
            <div class="column is-6">
              <form @submit="submitBanAppeal" class="card">
                <div class="card-header">
                  <div class="card-header-title">
                    <b-icon icon="hammer" />
                    <h1 class="is-size-3 ml-2">Ban Appeals</h1>
                  </div>
                </div>
                <div class="card-content">
                  <b-field label="Discord ID*">
                    <b-input
                      v-model="banAppeals.discordId"
                      placeholder="131857875973701633"
                      pattern="^[0-9]{16,20}$"
                      maxlength="20"
                      required
                      expanded>
                    </b-input>
                  </b-field>

                  <b-field label="Email or @Twitter*">
                    <b-input
                      v-model="banAppeals.email"
                      placeholder="clappy@mafdiscord.com (or @mafdiscord)"
                      pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))|(@?(\w){1,15})$'
                      maxlength="2048"
                      required
                      expanded>
                    </b-input>
                  </b-field>

                  <b-field label="Ban date*">
                    <b-datepicker
                      placeholder="Click me to open dates"
                      icon="calendar-today"
                      :min-date="new Date('15/jan/2017')"
                      :max-date="new Date()"
                      v-model="banAppeals.date"
                      required/>
                  </b-field>

                  <b-field label="Who banned you?">
                    <b-input
                      v-model="banAppeals.moderator"
                      placeholder="Jrewt"
                      maxlength="37"
                      expanded>
                    </b-input>
                  </b-field>

                  <b-field label="What were you banned for?*">
                    <b-input
                      v-model="banAppeals.reason"
                      placeholder="Bringing soup to the theatre"
                      expanded
                      required>
                    </b-input>
                  </b-field>

                  <b-field label="Why should you be unbanned?*">
                    <b-input
                      v-model="banAppeals.justification"
                      placeholder="There were no popcorn in the shop"
                      required
                      expanded>
                    </b-input>
                  </b-field>

                  <b-button
                    :loading="banAppealsLoading"
                    native-type="submit"
                    label="Submit ban appeal"
                    type="is-info"/>
                </div>
              </form>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import { banAppeal, contact } from '@/api/guild'
import { getUser } from '@/api/users'

export default {
  data: () => ({
    banAppealsLoading: false,
    banAppeals: {
      discordId: null,
      email: null,
      date: null,
      moderator: null,
      reason: null,
      justification: null
    },
    contactLoading: false,
    contact: {
      discordId: null,
      email: null,
      subject: null,
      description: null
    }
  }),
  async beforeMount () {
    if (!this.computedUser) { await this.fetchUser() }
    this.setId()
  },
  methods: {
    setId () {
      const id = this.computedUser.id
      if (id) {
        this.banAppeals.discordId = id
        this.contact.discordId = id
      }
    },
    defaultContact () {
      this.contact = {
        discordId: null,
        email: null,
        subject: null,
        description: null
      }
    },
    defaultBanAppeal () {
      this.banAppeals = {
        discordId: null,
        email: null,
        date: null,
        moderator: null,
        reason: null,
        justification: null
      }
    },
    async submitContact ($e) {
      this.contactLoading = true
      $e.preventDefault()

      try {
        await contact(this.contact)
        this.defaultContact()

        this.$buefy.notification.open({
          message: 'Contact request submitted successfully!',
          type: 'is-success'
        })
      } catch (err) {
        this.$buefy.notification.open({
          message: `Error: ${err.message}`,
          type: 'is-danger'
        })
      }
      this.contactLoading = false
    },
    async submitBanAppeal ($e) {
      this.banAppealsLoading = true
      $e.preventDefault()

      try {
        await banAppeal(this.banAppeals)
        this.defaultBanAppeal()
        this.$buefy.notification.open({
          message: 'Ban appeal submitted successfully',
          type: 'is-success'
        })
      } catch (err) {
        this.$buefy.notification.open({
          message: `Error: ${err.message}`,
          type: 'is-danger'
        })
      }
      this.banAppealsLoading = false
    },
    async fetchUser () {
      await this.$store.dispatch('user/setUser', await getUser())
    }
  },
  computed: {
    computedUser () {
      return (this.$store.state.user.user && this.$store.state.user.user.id) ? this.$store.state.user.user : null
    }
  }
}
</script>
